<template>
    <el-dialog :close-on-click-modal="false" title="消息详情" :visible.sync="show" width="500px" class="selfInputBox selfInputBoxIV">
        <!--        <div class="info">
            “绿水青山就是金山银山”，这一论断是习近平生态文明思想的核心理念。党的十八大以来，我国生态环境保护发生历史性、转折性、全局性变化，生态文明建设取得举世瞩目成就。我国率先提出和实施生态保护红线制度，推动建设世界上最大的国家公园体系，近十年全球增加的森林面积四分之一来自我国；我国以水电、风电、光伏发电为代表的可再生能源装机规模稳居全球首位，海上风电装机规模高居世界第一，新能源汽车产业正成为中国制造新名片……实践证明，绿水青山既是自然财富、生态财富，又是社会财富、经济财富，全国生态日将更好唤起我们建设美丽中国的成就感、自豪感。
        </div> -->

        <div class="info">
            {{dict.message}}
        </div>

    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                show: false,


                dict: {}
            }
        },
        methods: {
            open(row) {


                this.$http.get('/api//hazardousmessage/' + row.id).then((res) => {

                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {
                        // this.data.list = res.data.data
                        // this.data.total = res.data.total
                        // setTimeout(() => {
                        //     this.modifyLayout();
                        // }, 1100)

                        this.dict = res.data



                        this.formShow = true
                        this.show = true
                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });




            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            }
        }
    }
</script>

<style scoped>
    /*  .selfInputBoxIV .el-input__inner[placeholder="请选择配置分组"],
  .selfInputBoxIV .el-input__inner[placeholder="请选择配置类型"] {
    border: 1px red solid !important;
    box-sizing: content-box !important;
  } */
    /deep/.el-dialog__body {
        padding: 20px 20px 30px 20px;
    }

    .info {
        /* border: 1px red solid; */
        /* box-sizing: content-box; */

        font-size: 14px;
        font-weight: 400;
        color: #626262;
        line-height: 28px;
    }
</style>